export const layerNameMappings = {
    ownership: "Ownership Parcels",
    zoning: "Teton County Zoning",
    conservation_easements: "Conservation Easements",
    control_points_controls: "Control Points Controls",
    ownership_address: "Ownership Address",
    plss_plss_intersected: "PLSS Intersected",
    plss_plss_labels: "PLSS Labels",
    plss_plss_sections: "PLSS Sections",
    plss_plss_townships: "PLSS Townships",
    precincts_polling_centers: "Precinct Polling Centers",
    roads_easements: "Road Easements",
    roads: "Roads",
    zoning_toj_corp_limit: "Town of Jackson Corporate Limit",
    zoning_toj_zoning_overlay: "Town of Jackson Zoning Overlay",
    zoning_toj_zoning: "Town of Jackson Zoning",
    zoning_zoverlay: "Teton County Zoning Overlay",
    public_land: "Public Land",
    precincts: "Precincts",
    mooose_reprojected : "Critical Moose Habitat",
    reporjected_elk : "Critical Elk Habitat",
    bigHorn_reporjected : "Critical Big Horn Sheep Habitat",
    mule_deer_reporjected : "Critical Mule Deer Habitat",
    FEMA_updated: "FEMA Flood Zones"




  };
  